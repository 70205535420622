import { log } from "./logToBackend";
import { toastError } from "./toaster";
import { errorToObject } from "encharge-domain/lib/helpers/error";
import { getNodeEnv } from "domain/helpers/getNodeEnv";

window.addEventListener("error", (event) => {
  if (!event.filename) {
    // Error in external script that we don't have control over
    return;
  }
  if (event.message && event.message.indexOf("ResizeObserver") > -1) {
    // This is a resize observer error that we don't want to log
    // https://github.com/DevExpress/testcafe/issues/4857#issuecomment-598775956
    event.stopImmediatePropagation();
    return false;
  }
  toastError(
    "An error occured 😓. Please try refreshing this page. Contact support if it persists.",
    {
      toastId: "top-level-error-caught",
    }
  );
  if (getNodeEnv() === "production") {
    log({
      message: "Frontend Error",
      extra: {
        error: errorToObject(event.error),
        message: event.message,
        lineno: event.lineno,
        colno: event.colno,
        filename: event.filename,
      },
    });
  }
  return;
});

/// @ts-ignore
window.addEventListener("unhandledrejection", (event) => {
  if (
    event?.reason?.message?.includes?.("Object Not Found Matching Id") ||
    (typeof event?.reason === "string" &&
      event?.reason?.includes?.("Object Not Found Matching Id"))
  ) {
    // This is a common error from an extension or something that we don't want to log
    event.stopImmediatePropagation();
    return false;
  }
  // toastError(
  //   "An error occured 😓. Please try refreshing this page. Contact support if it persists.",
  //   {
  //     toastId: "top-level-error-caught",
  //   }
  // );
  if (getNodeEnv() === "production") {
    log({ message: "Frontend Unhandled Rejection", extra: event?.reason });
  }
});
